import React from 'react';
import styled from 'styled-components';

const Icon = styled.a`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  border: 0;
  height: 2rem;
  line-height: 1.5em;
  border-radius: 1.5rem;
  border: solid 1px gray;
  /* background: #e9e9eb85; */
  // background: #e9e9eb;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0.5rem 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    min-width: 0.5rem;
    min-height: 0.5rem;
    height: 1rem;
    width: 1rem;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-label {
    padding: 0.5rem 0rem 0.5rem 0.3rem;
  }

  &:hover {
    transform: scale(1.15);
    border-color: #7f0100;
  }
`;

const TechIcon = ({ icon }) => {
  return (
    <Icon href={icon.link} target="_blank" className="techicon frosted">
      <div className="icon">{icon.icon}</div>
      <div className="icon-label">{icon.title}</div>
    </Icon>
  );
};

export default TechIcon;

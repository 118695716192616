import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import useSiteMetadata from '../../graphql/staticQueries/useSiteMetadata';
import favicon16 from '../../../static/icons/favicon-16x16.png';
import favicon32 from '../../../static/icons/favicon-32x32.png';
import favicon192 from '../../../static/icons/android-chrome-192x192.png';

function SEO({
  description: specDescription,
  title: specTitle,
  image: specImage,
  url: specUrl,
}) {
  const {
    config: {
      siteUrl,
      shortName,
      mainImage,
      twitterUrl,
      themeColor,
      title: siteTitle,
      description: siteDescription,
    },
  } = useSiteMetadata();

  // if title and description is not provided, use those from siteMetadata
  const title = specTitle || siteTitle;
  const description = specDescription || siteDescription;
  const image = specImage || mainImage;
  const url = specUrl || siteUrl;

  return (
    <Helmet>
      {/* General tags */}
      <meta charset="utf-8" />
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="HandheldFriendly" content="True" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=no"
      />
      <meta name="image" content={image} />
      {/* Favicons */}
      <link rel="icon" type="image/png" sizes="16x16" href={`${favicon16}`} />
      <link rel="icon" type="image/png" sizes="32x32" href={`${favicon32}`} />
      <link rel="shortcut icon" type="image/png" href={`${favicon192}`} />
      {/* OpenGraph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={shortName} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="pl_PL" />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={title} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      {/* Twitter tags */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content={twitterUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
      <meta property="twitter:image:alt" content={title} />
      {/* Other tags */}
      <meta name="theme-color" content={themeColor} />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content={shortName} />
      <meta
        data-hid="author"
        name="author"
        content="Michał Popiel kraftartz.space@gmail.com"
      />
      <meta name="msapplication-config" content="browserconfig.xml" />
    </Helmet>
  );
}

SEO.defaultProps = {
  description: '',
  title: '',
  url: '',
  image: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
};

export default SEO;

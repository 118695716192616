import React from 'react';
import TechList from './Tech/TechList';
import { SubpageTitle } from './SimpleStyledComponents';
import { FaPython, FaReact, FaVuejs } from 'react-icons/fa';
import GatsbySVG from '../images/icons/gatsby.inline.svg';
import KubeSVG from '../images/icons/kube.inline.svg';
import BashSVG from '../images/icons/bash.inline.svg';
import FlutterSVG from '../images/icons/flutter.inline.svg';
import DjangoSVG from '../images/icons/django.inline.svg';
import TsSVG from '../images/icons/typescript.inline.svg';
import NuxtSVG from '../images/icons/nuxt.inline.svg';
import NodeSVG from '../images/icons/nodejs.inline.svg';
import FastAPISVG from '../images/icons/fastapi.inline.svg';
import FirebseSVG from '../images/icons/firebase.inline.svg';
import DockerSVG from '../images/icons/docker.inline.svg';
import { useTranslation } from 'react-i18next';

const techList = [
  {
    id: 'flutter',
    link: 'https://flutter.dev/',
    title: 'Flutter',
    icon: <FlutterSVG />,
  },
  {
    id: 'python',
    link: 'https://www.python.org/',
    title: 'Python',
    icon: <FaPython />,
  },
  {
    id: 'django',
    link: 'https://www.djangoproject.com/',
    title: 'Django',
    icon: <DjangoSVG />,
  },
  {
    id: 'fastapi',
    link: 'https://fastapi.tiangolo.com/',
    title: 'FastAPI',
    icon: <FastAPISVG />,
  },
  {
    id: 'bash',
    link: 'https://tiswww.case.edu/php/chet/bash/bashtop.html',
    title: 'Bash',
    icon: <BashSVG />,
  },
  {
    id: 'docker',
    link: 'https://www.docker.com/',
    title: 'Docker',
    icon: <DockerSVG />,
  },
  {
    id: 'kube',
    link: 'https://kubernetes.io/',
    title: 'Kubernetes',
    icon: <KubeSVG />,
  },
  {
    id: 'node',
    link: 'https://nodejs.org/ennode',
    title: 'Node',
    icon: <NodeSVG />,
  },
  {
    id: 'vue',
    link: 'https://vuejs.org/',
    title: 'Vue',
    icon: <FaVuejs />,
  },
  {
    id: 'react',
    link: 'https://reactjs.org/',
    title: 'React',
    icon: <FaReact />,
  },
  {
    id: 'typescript',
    link: 'https://www.typescriptlang.org/',
    title: 'TypeScript',
    icon: <TsSVG />,
  },
  {
    id: 'nuxt',
    link: 'https://nuxt.com/',
    title: 'Nuxt',
    icon: <NuxtSVG />,
  },
  {
    id: 'firebase',
    link: 'https://firebase.google.com/',
    title: 'Firebase',
    icon: <FirebseSVG />,
  },
  {
    id: 'gatsby',
    link: 'https://www.gatsbyjs.org/',
    title: 'Gatsby',
    icon: <GatsbySVG />,
  },
];

const Technologies = () => {
  const { t } = useTranslation();
  return (
    <div className="fadeIn">
      <SubpageTitle>{t('technologies')}</SubpageTitle>
      <TechList listData={techList} />
    </div>
  );
};

export default Technologies;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      technologies: 'Technologies',
      language: 'Language',
      about:
        'Independent software development.\n\nFocused on building mobile apps and handling software projects across the full stack — frontend, backend, and infrastructure.\n\nCapable of developing complete solutions or providing project support, with experience in technologies from Vue to Python, Bash to Kubernetes.\n\nGet in touch.',
      get_in_touch: 'GET IN TOUCH',
      form: {
        name: 'name',
        email: 'email',
        text: 'message',
        submit: 'Submit',
        thankyou: 'Thank you for the message!',
      },
    },
  },
  pl: {
    translation: {
      technologies: 'TECHNOLOGIE',
      language: 'Język',
      about:
        'Niezależne studio oprogramowania.\n\nSpecjalizacja w tworzeniu aplikacji mobilnych oraz realizacji projektów programistycznych na różnych poziomach – frontend, backend i infrastruktura.\n\nMożliwość budowy kompletnych rozwiązań lub wsparcia w projekcie, z doświadczeniem w technologiach od Vue po Pythona, od Basha po K8s.\n\nPorozmawiajmy.',
      get_in_touch: 'SKONTAKTUJ SIĘ',
      form: {
        name: 'imię',
        email: 'email',
        text: 'wiadomość',
        submit: 'Wyślij',
        thankyou: 'Dzięki za wiadomość!',
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    // lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
  });
export default i18n;

import React, { useState } from 'react';
import styled from 'styled-components';
import { IoPaperPlaneOutline } from 'react-icons/io5';

import { useTranslation } from 'react-i18next';
const ContactFormStyled = styled.form`
  width: 600px;
  max-width: 90%;
  margin: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  border-radius: 3px;

  .row {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    justify-content: stretch;

    input,
    textarea,
    button {
      width: 100%;
      padding: 2px 4px;
      border-radius: 3px;
      border: none;
    }
    textarea {
      min-height: 100px;
    }
    button {
      font-size: 1rem;
      color: #3d3d3d;
      cursor: pointer;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .top {
    input:first-of-type {
      margin-right: 1rem;
    }
  }
`;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const ContactForm = () => {
  const [send, setSend] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': event.target.getAttribute('name'),
        name: event.target.elements.name.value,
        email: event.target.elements.email.value,
        text: event.target.elements.text.value,
      }),
    })
      .then(() => setSend(true))
      .catch((error) => alert(error));
  };

  const { t } = useTranslation();
  return (
    <>
      {!send ? (
        <ContactFormStyled
          data-netlify="true"
          onSubmit={handleSubmit}
          name="aerial-contact-form"
          className="frosted"
        >
          <div className="row top">
            <input
              type="text"
              id="name"
              name="name"
              placeholder={t('form.name')}
              required
            />
            <input
              type="text"
              id="email"
              name="email"
              placeholder={t('form.email')}
              required
            />
          </div>
          <div className="row">
            <textarea
              id="text"
              name="text"
              placeholder={t('form.text')}
              required
            ></textarea>
          </div>

          <div className="row">
            <button type="submit">
              {t('form.submit')} <IoPaperPlaneOutline />
            </button>
          </div>
        </ContactFormStyled>
      ) : (
        <div className="fadeIn thankyou">{t('form.thankyou')}</div>
      )}
    </>
  );
};

export default ContactForm;

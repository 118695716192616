import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { IoFingerPrint } from 'react-icons/io5';
import { GiPokecog } from 'react-icons/gi';

import MainLogo from '../MainLogo';
import SEO from './SEO';
import Technologies from '../Technologies';
import Contact from '../Contact';
import About from '../About';
import ContactForm from '../ContactForm';
import LanguageSwitcher from '../LanguageSwitcher';
import i18n from './i18n';

import Typed from 'typed.js';

const Layout = ({
  location,
  children,
  title,
  description,
  seoImage,
  urlCanonical,
  data,
}) => {
  const el = React.useRef(null);
  const typed = React.useRef(null);

  React.useEffect(() => {
    // Small timeout to let rendering settle
    const timeout = setTimeout(() => {
      document.body.classList.add('loaded');
    }, 50);

    // Cleanup timeout on component unmount
    return () => clearTimeout(timeout);
  }, []);

  React.useEffect(() => {
    const options = {
      strings: ['<span>kraftartz</span>'],
      typeSpeed: 100,
      startDelay: 400,
      showCursor: false,
    };

    typed.current = new Typed(el.current, options);

    return () => {
      typed.current.destroy();
    };
  }, []);

  const year = new Date().getFullYear();
  return (
    <>
      <SEO
        title={title}
        description={description}
        image={seoImage}
        url={urlCanonical}
      />
      <div id="wrapper" className="maincss">
        <div id="bg" />
        <div id="overlay" />
        <div id="subpage-overlay" />
        <div id="main">
          <LanguageSwitcher />
          <header id="header">
            <h1>
              {/* <div>
                <MainLogo />
              </div> */}
              <div className="type-wrap homeLink">
                <span className="icon">{'_'}</span>
                <span
                  style={{ whiteSpace: 'pre' }}
                  className="header-font"
                  ref={el}
                />
              </div>
            </h1>
            <About className="fadeIn" />
            <nav>
              <ul>
                <li>
                  <div className={`icon brands`}>
                    <GiPokecog />
                    <span className="label">technology</span>
                  </div>
                </li>
              </ul>
            </nav>
            {children}
            <Technologies />
            <Contact className="fadeIn" />
            <ContactForm />
            <span className="copywrongs">&copy; {year}.</span>
          </header>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
